import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";
import { GcalEventSchema } from "./gcal-event";
import { SmartActionSchema } from "./smart-action";

const ApptStatusEnum = z.enum([
  "confirmed",
  "cancelled",
  "declined",
  "maybe",
  "pending",
  "deleted",
  "shared",
  "tentative",
  "accepted",
  "needsAction",
]);

const FormReminderSchema = z.object({
  formId: z.string().optional(),
});

const ApptLocationEnum = z.enum([
  "google-call",
  "zoom-call",
  "video-call",
  "voice-call",
  "outgoing-call",
  "in-person",
  "skype-consumer-call",
  "skype-business-call",
  "teams-call",
]);

const ApptDashboardSchema = z.object({
  payments: z.literal("dismissed").optional(),
});

const AppointntEventDataSchema = z.object({
  icon: z.string(),
  attendeesLimit: z.string(), // Fix type
  description: z.string().optional(),
  productId: z.string().optional(),
  smartActions: z.array(SmartActionSchema).optional(),
});

export const OutcomeHistorySchema = z.object({
  outcomeId: z.string().nullable(),
  date: z.date(),
  memberId: z.string(),
});

export const AppointmentOutcomeSchema = z.object({
  outcomeId: z.string(),
  createdBy: z.string(),
  note: z.string().optional(),
  origin: z.string().optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const ProviderSchema = z.enum(["google", "microsoft"]);
export const AppointmentReschedulingOptionsSchema = z.object({
  allowedActions: z.enum(["none", "cancel"]),
  minimumNotice: z.object({ duration: z.number() }),
  reasonRequired: z.boolean(),
});

export type AppointmentReschedulingOptionsType = z.infer<
  typeof AppointmentReschedulingOptionsSchema
>;

export const AppointmentContactLockSchema = z.object({
  lockedAt: z.date(),
  lockedBy: z.string(),
});

export const GroupSchedulerInformation = z.object({
  groupSchedulerId: z.string(),
  timeslotId: z.string(),
  recurringInstanceDate: z.string().optional(),
  memberId: z.string().optional(),
  attendees: z.array(
    z.object({
      id: z.string(),
      packageInstanceId: z.string().optional(),
      outcomeId: z.string().optional(),
    })
  ),
});

export type GroupSchedulerInformationType = z.infer<
  typeof GroupSchedulerInformation
>;

export const AppointmentSchema = BaseSchema.extend({
  title: z.string().nullable(),
  start: z.date(),
  end: z.date(),
  timeZone: z.string().optional(),
  status: ApptStatusEnum,
  paymentId: z.string().optional(),
  paymentIds: z.string().array().optional(),
  packageInstanceId: z.string().optional().nullable(),
  formId: z.string().optional(),
  formAfter: FormReminderSchema.optional(),
  formBefore: FormReminderSchema.optional(),
  customEventInformation: z
    .object({
      eventId: z.string(),
      organizer: z.string(),
    })
    .optional(),
  gcal: z
    .object({
      event: GcalEventSchema,
    })
    .optional(),
  contactId: z.string().nullable().optional(),
  clientParentId: z.string().optional().nullable(),
  contactIds: z.array(z.string()).optional(),
  locationType: ApptLocationEnum.optional(),
  locationMemberId: z.string().optional(),
  repeat: z.any(), // @TODO: fix type
  ISOstart: z.string().optional(),
  ISOend: z.string().optional(),
  dashboard: ApptDashboardSchema.optional(),
  noteIds: z.array(z.string()).optional(),
  slug: z.string().optional(),
  location: z.string().optional(),
  gcalCalendar: z.any().optional(), // @TODO: fix type
  googleAccountId: z.string().nullable().optional(),
  eventData: AppointntEventDataSchema.optional(),
  groupId: z.string().optional(),
  isEvent: z.boolean().optional(),
  hidden: z.boolean().default(false),
  availabilityId: z.string().optional(),
  recurring: z.boolean().optional(),
  allDayEvent: z.boolean().optional(),
  syncCreated: z.boolean().optional(),
  origin: z.string().optional(),
  smartActions: z.any().optional(),
  outcome: AppointmentOutcomeSchema.optional().nullable(),
  outcomeHistory: z.array(OutcomeHistorySchema).optional(),
  packageInstanceCycle: z.number().nullable().optional(),
  internalFormIds: z.array(z.string()).optional(),
  reschedulingOptions: AppointmentReschedulingOptionsSchema.optional(),
  memberIds: z.array(z.string()).optional(),

  // @TODO: all props below are deprecated and not been used.
  //        It was added here to make the schema validation works, and
  //        all of them should be deleted once the database is fixed without them
  reminders: z.any().optional(),
  vendorId: z.string().optional(),
  organizerId: z.string().optional(),
  calendarId: z.string().optional(),
  metadata: z.any().optional(),
  provider: ProviderSchema.optional(),
  groupSchedulerInformation: GroupSchedulerInformation.optional(),
  groupSchedulerTimeslotId: z.string().optional(),
  integrationInfo: z
    .object({
      memberId: z.string(),
      email: z.string(),
      integrationId: z.string().optional(),
      provider: ProviderSchema.optional(),
    })
    .optional(),
  contactLock: AppointmentContactLockSchema.nullable().optional(),
});

export type AppointmentType = z.infer<typeof AppointmentSchema>;

export type AppointmentOutcomeType = z.infer<typeof AppointmentOutcomeSchema>;

export type OutcomeHistoryType = z.infer<typeof OutcomeHistorySchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "appointments",
  deleteMode: "none",
};

import React, { useEffect, useMemo, useState } from "react";
import { useController, useWatch } from "react-hook-form";
import ReactSelect, {
  ActionMeta,
  components,
  ContainerProps,
  ControlProps,
  InputProps,
  MenuListProps,
  MenuProps,
  MultiValueProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  ValueContainerProps,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import classNames from "classnames";
import { get, isArray, isEqual, omit } from "lodash";

import { formValueIsSelected } from "@lib/utils/forms/formValueIsSelected";
import { colors } from "@lib/utils/theme";

import { InputError } from "@components/Form/InputError";
import InfoIcon from "@components/Icons/InfoIcon";
import LockIcon from "@components/Icons/LockIcon";
import { Tooltip } from "@components/Tooltips/Tooltip";

import { SelectFormOption, SelectFormProps } from "./types";

const customStyles: StylesConfig<SelectFormOption> = {
  option: (styles) => omit(styles, ["backgroundColor", "color"]),
  menu: (styles) => ({ ...styles, zIndex: 20 }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: colors && colors["grey-950"],
    display: "flex",
    alignItems: "center",
  }),
  control: (styles) =>
    omit(styles, [
      "backgroundColor",
      "borderColor",
      "borderRadius",
      "borderStyle",
      "borderWidth",
      "&:hover",
      "transition",
      "boxShadow",
    ]),
};
type ValueType = { value: string | string[] };

const getMultiValues = (props: MultiValueProps | OptionProps) =>
  props
    .getValue()
    .map((item: ValueType) => item.value)
    .flat();

const customComponents = {
  CheckBoxOption: (props: OptionProps & ValueType) => {
    const customStyles = props?.selectProps?.classNames?.optionClassNames;
    const multiValues = getMultiValues(props);
    const isSelected = formValueIsSelected(multiValues, props.value);
    const checked = props.isSelected || isSelected;
    const removeValue = () => {
      const newValues = props.getValue().filter((v: ValueType) => {
        return isEqual(props.value, v.value);
      });
      props.setValue(newValues, "deselect-option");
    };
    const { innerProps, ...restProps } = props;
    const { onClick, ...rest } = innerProps;
    const newOnClick = checked ? removeValue : onClick;
    const newInnerProps = { onClick: newOnClick, ...rest };

    return (
      <components.Option
        innerProps={newInnerProps}
        {...restProps}
        className={classNames(restProps.className, customStyles)}
      >
        <div className="flex gap-4 items-center">
          <div className="invert">
            <input
              type="checkbox"
              className="checked:bg-black checked:hover:bg-black checked:hover:border-white bg-black border-white checked:border-white h-5 w-5 border-2 rounded-sm hover:cursor-pointer "
              onChange={() => (checked ? removeValue() : null)}
              checked={checked}
            />
          </div>
          <label>{props.label}</label>
        </div>
      </components.Option>
    );
  },
  Option: (props: OptionProps) => {
    const customStyles = props?.selectProps?.classNames?.optionClassNames;
    return (
      <components.Option
        {...props}
        className={classNames(
          "text-black",
          {
            "bg-action-700": props?.isSelected && !customStyles,
            "bg-action-900":
              props?.isFocused && !props?.isSelected && !customStyles,
          },
          customStyles
        )}
      />
    );
  },
  Input: (props: InputProps) => (
    <components.Input
      {...props}
      className="!flex"
      inputClassName="outline-none border-none shadow-none focus:ring-transparent"
    />
  ),
  Control: (props: ControlProps) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { error, disabled, isRightComponent, name } = props.selectProps;
    const isBuffer = name?.includes("bufferTime");
    return (
      <components.Control
        {...props}
        className={classNames(
          "mt-1 py-0.5 px-1 w-full border rounded-md transition duration-150 ease-in-out",
          isBuffer && "border-none rounded-md mt-0",
          {
            "border-peach-600 text-red-900 focus:border-red-300 focus:ring-0":
              error,
            "border-red-300 ring-0": error && props?.isFocused,
            "border-grey-900 hover:bg-grey-950 hover:border-grey-950":
              !error && !props?.isFocused && !props?.isMulti,
            "outline-none ring-0 shadow-none bg-white border-action-700":
              props?.isFocused,
            "bg-grey-950 cursor-not-allowed border-grey-900":
              disabled || props.isDisabled,
            "rounded-l-none": isRightComponent,
          },
          props.selectProps.classNames.controlClassNames
        )}
      />
    );
  },
  Container: (props: ContainerProps) => (
    <components.SelectContainer {...props} className="relative flex flex-1" />
  ),
  Menu: (props: MenuProps) => <components.Menu {...props} className="z-20" />,
  ValueContainer: (props: ValueContainerProps) => (
    <components.ValueContainer
      {...props}
      className="sm:!overflow-visible min-w-0"
    />
  ),
  MenuList: (props: MenuListProps) => (
    <components.MenuList
      {...props}
      className={props.selectProps?.classNames?.menuClassNames}
    />
  ),

  MultiValue: (props: MultiValueProps & { data: ValueType }) => {
    const showAllMultiValues = props?.selectProps?.showAllMultiValues;
    const getLength = (option: ValueType) => {
      if (isArray(option.value)) return option.value.length;
      return 1;
    };

    if (props.index === 2 && !showAllMultiValues) {
      const multiValues = props.getValue();
      const multiLength = getMultiValues(props)?.length;
      const firstLengths =
        getLength(multiValues[0] as ValueType) +
        getLength(multiValues[1] as ValueType);
      return (
        <div className="bg-grey-950 text-xs py-1 px-2 ml-1 font-medium">{`+${
          multiLength - firstLengths
        }`}</div>
      );
    } else if (props.index > 2 && !showAllMultiValues) {
      return null;
    }
    const multi = props?.data?.value;
    const valueClassNames = props?.selectProps?.classNames?.valueClassNames;
    const comp = (
      <components.MultiValue
        {...props}
        className={classNames("flex gap-2", valueClassNames)}
      />
    );
    return Array.isArray(multi) ? (
      <Tooltip
        placement="bottom"
        contentClassName="ml-0 mt-0 text-xs py-2 px-3"
        trigger={comp}
      >
        <div>
          {multi.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </div>
      </Tooltip>
    ) : (
      comp
    );
  },
  SingleValue: (props: SingleValueProps) => {
    if (props.selectProps?.hideValue) props.clearValue();
    return (
      <components.SingleValue
        {...props}
        className={props.selectProps?.classNames?.valueClassNames}
      />
    );
  },
};

const DropdownIndicatorDisabled = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <LockIcon />
    </components.DropdownIndicator>
  );
};

const SelectForm = function <T = SelectFormOption>({
  control,
  errors,
  options,
  name,
  label,
  placeholder,
  containerClassName = "",
  required = false,
  requiredErrorMessage,
  canAdd = false,
  disabled = false,
  isRightComponent = false,
  handleCreate,
  createFieldForSelectedLabel = false,
  selectedLabelFieldName,
  setValue,
  register,
  tooltip,
  tooltipSize,
  selectProps = {},
  indicatorSeparator = true,
  dropdownIndicator = true,
  menuPosition = "absolute",
  defaultValue = "",
  onInputChange,
  checkBoxOption = false,
  CustomMenuList,
  customSearchKey,
  isClearable = false,
  flatMultiValue = false,
}: SelectFormProps<T>) {
  const error = get(errors, name);

  const selectComponents = {
    Input: customComponents.Input,
    Control: customComponents.Control /*(error, false)*/,
    Option: customComponents.Option,
    SelectContainer: customComponents.Container,
    MultiValue: customComponents.MultiValue,
    ValueContainer: customComponents.ValueContainer,
    MenuList: customComponents.MenuList,
    IndicatorSeparator: components.IndicatorSeparator,
    DropdownIndicator: components.DropdownIndicator,
    SingleValue: customComponents.SingleValue,
  };

  const [newestOption, setNewestOption] = useState<
    SelectFormOption | undefined
  >(undefined);

  const SelectComponent = useMemo(
    () => (canAdd ? CreatableSelect : ReactSelect),
    []
  );

  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
    rules: {
      required: { value: required, message: requiredErrorMessage || "" },
    },
    defaultValue,
  });

  const fieldIdValue = useWatch({
    control,
    name,
  }) as string;

  useEffect(() => {
    if (!createFieldForSelectedLabel) {
      return;
    }
    const selectedOption = [
      ...options,
      ...(newestOption ? [newestOption] : []),
    ].find((option) => option.value === fieldIdValue);
    selectedLabelFieldName &&
      setValue(selectedLabelFieldName, selectedOption?.label);
  }, [fieldIdValue]);

  const handleOnChange = (
    newValue: SelectFormOption | SelectFormOption[],
    actionMeta: ActionMeta<unknown>
  ) => {
    if (selectProps?.isMulti && isArray(newValue)) {
      onChange(newValue?.map((o: SelectFormOption) => o.value));
    } else {
      onChange(newValue?.value);
    }
    if (onInputChange) onInputChange(newValue, actionMeta);
  };

  const buildOptionValue = (value: string | number) => {
    const formattedValue = typeof value === "number" ? value.toString() : value;
    if (flatMultiValue) {
      return options.filter((option) =>
        formattedValue.includes(option.value as string)
      );
    }
    return options.find((option) => option.value === formattedValue);
  };

  const onCreateOption = (inputValue: string) => {
    const newOption = handleCreate && handleCreate(inputValue);
    setNewestOption(newOption);
    onChange(newOption?.value);
  };

  if (!indicatorSeparator) selectComponents.IndicatorSeparator = () => <></>;
  if (!dropdownIndicator) selectComponents.DropdownIndicator = () => <></>;

  selectComponents.MenuList = CustomMenuList
    ? CustomMenuList
    : customComponents.MenuList;
  selectComponents.Option = checkBoxOption
    ? customComponents.CheckBoxOption
    : customComponents.Option;

  const customTheme = colors && {
    primary: colors["action-800"],
    primary50: colors["action-900"],
    primary25: colors["action-950"],
    neutral20: colors["grey-900"],
    neutral30: colors["grey-800"],
    // neutral danger colors for removing multivalue
    dangerLight: colors["grey-950"],
    danger: colors["grey-100"],
  };

  return (
    <div
      className={classNames(containerClassName, {
        "cursor-not-allowed": disabled,
      })}
    >
      {label && (
        <label
          htmlFor={name}
          className="flex items-center justify-between text-sm leading-5 text-grey-500 w-full"
        >
          <span>{label}</span>
          {tooltip && (
            <Tooltip
              className="inline-block ml-2 text-grey-500 hover:text-black-ink"
              trigger={<InfoIcon />}
              size={tooltipSize}
            >
              {tooltip}
            </Tooltip>
          )}
        </label>
      )}
      {createFieldForSelectedLabel && (
        <input
          type="hidden"
          name={selectedLabelFieldName}
          {...register?.(selectedLabelFieldName)}
        />
      )}
      <SelectComponent
        isDisabled={disabled}
        isOptionDisabled={(option) => option.disabled}
        options={options}
        name={name}
        placeholder={placeholder}
        value={buildOptionValue(value)}
        onChange={handleOnChange}
        filterOption={
          customSearchKey
            ? ({ data }, searchString) =>
                searchString
                  ?.split(" ")
                  .map((search) =>
                    data.searchKey.toLowerCase().includes(search.toLowerCase())
                  )
                  .every(Boolean)
            : undefined
        }
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            ...customTheme,
          },
        })}
        onBlur={onBlur}
        styles={customStyles}
        ref={ref}
        onCreateOption={onCreateOption}
        {...selectProps}
        components={{
          ...selectComponents,
          ...(selectProps.components || {}),
          ...(disabled && { DropdownIndicator: DropdownIndicatorDisabled }),
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={error}
        isRightComponent={isRightComponent}
        menuPosition={menuPosition}
        menuPlacement={selectProps.menuPlacement ?? "auto"}
        defaultValue={defaultValue}
        isClearable={isClearable}
      />
      <InputError error={error} />
    </div>
  );
};

export default SelectForm;
